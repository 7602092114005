
$(function(){	

	let disabled_welcome_email = document.querySelector( "[name=disabled_welcome_email]" )
    if( disabled_welcome_email )
        disabled_welcome_email.value = 1

	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation 
	$('#menu-btn').click(function(event) {
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({left:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	$('.close-menu').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({left:'100%'},{queue:false,duration:300});
	});
	
	//SUBNAVIGATION
	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');

	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        } 
    });

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	//Prevents fancybox links from opening
	$("a.fancybox").on("click", function(e){
		e.preventDefault();
	});

	$('.stats__list').slick({
		arrows: false,
		dots: true
	});
	$('.u-events__list').slick({
		arrows: false,
		dots: true
	});

	$(".js-open-event").click(function(e) {
		e.preventDefault();
		console.log("here");
		var target = $(this).data('target');

		$.get( "/ajax/functions.php", {target: target, operation: "getEventDetail"}, function(data){
			$(".event-popup__top img").attr( "src", data.image );
			$(".event-popup__title").html(data.event_name);
			$(".event-popup__date").html(data.date_start);

			if(data.website != "http://"){
				$(".btn.solid").show();
				$(".btn.solid").attr("href", data.website)
			}else{
				$(".btn.solid").hide();
			}

			if(data.website_label)
				$(".btn.solid").text(data.website_label);
			else     
				$(".btn.solid").text("Register Now");

			$(".event-popup__bottom").html(data.description);
			$(".event-popup").show();
		}, "json" )

		$('.modal-overlay').fadeIn();
		$('#'+target).fadeIn();
	});
});

