if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

const listBlockWithFilter = () => {
    document.querySelectorAll( '.list-block.with-filter' ).forEach( list_block => {
        let filter_items = list_block.querySelectorAll('li.list-block__filter-item');
        let before_filter_clicked = null;

        filter_items.forEach( li => {
            li.addEventListener( 'click', () => {
                if( before_filter_clicked ){
                    before_filter_clicked.classList.remove('filter_selected');
                }
                li.classList.add('filter_selected');
                before_filter_clicked = li;

                list_block.querySelectorAll('.list-block__item').forEach( item => {
                    if( li.dataset.filter == 'all' ){
                        item.classList.remove('hidden');
                    }else if( item.dataset.tag == li.dataset.filter ){
                        item.classList.remove('hidden');
                    }else{
                        item.classList.add('hidden');
                    }
                } );
            } );
        } );

        filter_items[0].click();

    } );

    if( window.location.search !== '' ){
        console.log(window.location.search.split('=')[0]);
        
        if( window.location.search.split('=')[0] == '?filter'){
            let search_from_url = window.location.search.split('=')[1];
            search_from_url.replaceAll(' ', '_');
            search_from_url.toLowerCase;
            let element_of_filter = document.querySelector(`.list-block.with-filter li[data-filter="${search_from_url}"]`);
            if( element_of_filter ){
                element_of_filter.click();
            }else{
                console.log(`filtering from the URL has failed, the ${search_from_url} parameter has not been found in the filter.`);
            }
        }
    }
}

(function(){

    let disabled_welcome_email = document.querySelector( "[name=disabled_welcome_email]" )
    if( disabled_welcome_email )
        disabled_welcome_email.value = 1

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
	paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


    $('.sym-slides').slick({
        accessibility: false
    });

    $('.slider-events').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  arrows:false,
                  adaptiveHeight:true
                }
              },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                adaptiveHeight:true
              }
            },
        ]
      });


		$(".js-open-event").click(function(e) {
			e.preventDefault();
			var target = $(this).data('target');

            $.get( "/ajax/functions.php", {target: target, operation: "getEventDetail"}, function(data){
                $(".event-popup__top img").attr( "src", data.image );
                $(".event-popup__title").html(data.event_name);
                $(".event-popup__date").html(data.date_start);

                if(data.website != "http://"){
                    $(".btn.solid").show();
                    $(".btn.solid").attr("href", data.website)
                }else{
                    $(".btn.solid").hide();
                }

                if(data.website_label)
                    $(".btn.solid").text(data.website_label);
                else     
                    $(".btn.solid").text("Register Now");

                $(".event-popup__price").html(data.price);
                $(".event-popup__short").html(data.short_description);
                $(".event-popup__bottom").html(data.description);
                $(".event-popup").show();
            }, "json" )

			$('.modal-overlay').fadeIn();
			$('#'+target).fadeIn();
		});
		$(".js-close-event").click(function(e) {
			e.preventDefault();
			$('.modal-overlay').fadeOut();
			$('.event-popup').fadeOut(); 
		});

    document.addEventListener('DOMContentLoaded', () => {
        if( document.querySelector('.list-block.with-filter') ){
            listBlockWithFilter();
        }     
    });

})();